import React, { useState } from "react";

function ContactUs() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        message: "",
    });

    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                access_key: "95a4417c-752c-425d-b691-20bac578b6c8",
                name: formData.name,
                email: formData.email,
                mobile: formData.mobile,
                message: formData.message,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setFormSubmitted(true);
                    setFormData({ name: "", email: "", mobile: "", message: "" });
                } else {
                    alert("Failed to send message. Please try again later.");
                }
            })
            .catch((error) => {
                console.error("Error sending email:", error);
            });
    };

    const closeOverlay = () => {
        setFormSubmitted(false);
    };

    const styles = {
        container: {
            background: "linear-gradient(to bottom, #00aaff, #003366)",
            color: "white",
            padding: "50px 20px",
            minHeight: "100vh",
        },
        sectionTitle: {
            textAlign: "center",
            fontSize: "36px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: "white",
        },
        form: {
            maxWidth: "600px",
            margin: "0 auto",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        },
        inputField: {
            width: "100%",
            padding: "12px",
            marginBottom: "20px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            fontSize: "16px",
            backgroundColor: "#f9f9f9",
        },
        submitButton: {
            backgroundColor: "#00aaff",
            color: "white",
            border: "none",
            padding: "15px 30px",
            fontSize: "18px",
            fontWeight: "bold",
            cursor: "pointer",
            borderRadius: "8px",
            transition: "background-color 0.3s ease",
        },
        submitButtonHover: {
            backgroundColor: "#003366",
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "24px",
            textAlign: "center",
            zIndex: 1000,
        },
        overlayButton: {
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#00aaff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "18px",
        },
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.sectionTitle}>Contact Us</h1>

            <form style={styles.form} onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    style={styles.inputField}
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    style={styles.inputField}
                    required
                />
                <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    placeholder="Your Mobile Number"
                    style={styles.inputField}
                    required
                />
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your Message"
                    style={{ ...styles.inputField, height: "150px" }}
                    required
                />
                <button
                    type="submit"
                    style={styles.submitButton}
                    onMouseOver={(e) => (e.target.style.backgroundColor = styles.submitButtonHover.backgroundColor)}
                    onMouseOut={(e) => (e.target.style.backgroundColor = styles.submitButton.backgroundColor)}
                >
                    Submit
                </button>
            </form>

            {formSubmitted && (
                <div style={styles.overlay}>
                    <div>
                        Thank you for reaching out! We will get back to you soon.
                        <button style={styles.overlayButton} onClick={closeOverlay}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ContactUs;
