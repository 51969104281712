import React from "react";
import './App.css';
import Home from "./components/pages/Home";
import ContactUs from "./components/pages/Contact";
import About from "./components/pages/AboutUs";
import NavBar from "./components/inc/NavBar";
import Footer from "./components/inc/Footer"
import { BrowserRouter as Router, Route , Routes } from "react-router-dom";
function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
        <Route path="/" element={<Home />} />
          
        <Route path="/about" element={< About/>} />
        <Route path="/contact" element={<ContactUs />} />
        </Routes>
        <Footer />

      </div>

    </Router>


  );
}

export default App;
