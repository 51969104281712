import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link if you want to use navigation
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

function Footer() {
    const [hovered, setHovered] = useState(null); // Track hover state

    // Inline styles for the footer
    const footerStyles = {
        backgroundColor: "#003366", // Dark Blue Color
        color: "white",
        paddingTop: "20px",
        paddingBottom: "20px",
    };

    const socialIconsStyles = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    const socialIconStyles = {
        color: "white",
        textDecoration: "none",
        margin: "0 10px",
        fontSize: "30px",
        transition: "color 0.3s", // Smooth transition for hover effect
    };

    // Apply hover effect to change icon color
    const getSocialIconHoverStyle = (hovered) => {
        return hovered ? { color: "#007bff" } : { color: "white" };
    };

    return (
        <footer style={footerStyles}>
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-6">
                        {/* Make the text white */}
                        <p style={{ color: "white" }}>&copy; 2024 CIITS. All rights reserved.</p>
                    </div>
                    <div className="col-md-6">
                        <div style={socialIconsStyles}>
                            <Link 
                                to="https://www.instagram.com/ciits24?igsh=eDFlYXd0czFvZTc4" 
                                target="_blank" 
                                style={{ ...socialIconStyles, ...getSocialIconHoverStyle(hovered === 'facebook') }}
                                onMouseEnter={() => setHovered('facebook')}
                                onMouseLeave={() => setHovered(null)}
                            >
                                <FaFacebook />
                            </Link>
                            <Link 
                                to="https://x.com/contactciits?t=q3tB7vKEypefWYoDydo2KA&s=08" 
                                target="_blank" 
                                style={{ ...socialIconStyles, ...getSocialIconHoverStyle(hovered === 'x') }}
                                onMouseEnter={() => setHovered('twitter')}
                                onMouseLeave={() => setHovered(null)}
                            >
                                <FaTwitter />
                            </Link>
                            <Link 
                                to="https://www.linkedin.com/company/central-india-it-services/" 
                                target="" 
                                style={{ ...socialIconStyles, ...getSocialIconHoverStyle(hovered === 'linkedin') }}
                                onMouseEnter={() => setHovered('linkedin')}
                                onMouseLeave={() => setHovered(null)}
                            >
                                <FaLinkedin />
                            </Link>
                            <Link 
                                to="https://www.instagram.com/ciits24?igsh=eDFlYXd0czFvZTc4" 
                                target="_blank" 
                                style={{ ...socialIconStyles, ...getSocialIconHoverStyle(hovered === 'instagram') }}
                                onMouseEnter={() => setHovered('instagram')}
                                onMouseLeave={() => setHovered(null)}
                            >
                                <FaInstagram />
                            </Link>
                        </div>
                    </div>
                </div>
                
                {/* Contact and Address Section with white text */}
                <div className="row mt-4">
                    <div className="col-md-6" style={{ padding: "10px" }}>
                        <h6 style={{ color: "white", fontWeight: "bold" }}>Contact Us</h6>
                        <p><a href="mailto:contact@ciits.com" style={{ color: "white", textDecoration: "none" }}>contact@ciits.in</a></p>
                        <p style={{ color: "white" }}>Phone: <span>+91 8770746285</span></p>
                    </div>
                    <div className="col-md-6" style={{ padding: "10px" }}>
                        <h6 style={{ color: "white", fontWeight: "bold" }}>Our Address</h6>
                        <p style={{ color: "white" }}>CIITS Office,</p>
                        <p style={{ color: "white" }}>123, IT Park Road,</p>
                        <p style={{ color: "white" }}>Bhopal, Madhya Pradesh,</p>
                        <p style={{ color: "white" }}>India - 462001</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
