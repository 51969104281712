import React from "react";
import Slider1 from "../images/CIITS_page-0003.png";
import Slider2 from "../images/Slider2.png";
import Slider3 from "../images/Slider3.png";

function Slider() {
    // Define image styles
    const sliderImageStyle = {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        maxHeight: '400px',
    };

   

    return (
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2" />
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3" />
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={Slider1} className="slider-image" alt="..." style={sliderImageStyle} />
                </div>
                <div className="carousel-item">
                    <img src={Slider2} className="slider-image" alt="..." style={sliderImageStyle} />
                  
                </div>
                <div className="carousel-item">
                    <img src={Slider3} className="slider-image" alt="..." style={sliderImageStyle} />
                  
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Slider;
