import React from "react";

function VMC() {
    return (
        <section className="section bg-c-light border-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3 className="main-heading">Vision, Mission and Values</h3>
                        <div className="underline mx-auto"></div>
                    </div>
                    <div className="col-md-4 text-center">
                        <h6>Our Vision</h6>
                        <p>To be a leading force in the IT industry, empowering businesses to succeed through cutting-edge technology, innovative solutions, and a commitment to excellence. We envision a future where our services drive digital transformation, helping companies achieve sustainable growth and a competitive edge in the evolving digital landscape.</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <h6>Our Mission</h6>
                        <p>Our mission is to provide high-quality, customized IT services that simplify complex challenges and optimize business performance. By leveraging the latest in technology and a customer-focused approach, we aim to deliver solutions that enhance productivity, improve security, and enable our clients to thrive in a digital-first world. We are dedicated to being a trusted partner, empowering our clients through each stage of their digital journey.</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <h6>Our Core Values</h6>
                        <p>We are driven by a commitment to innovation, always seeking creative ways to deliver cutting-edge solutions that meet our clients' needs. Customer focus is at the heart of our approach, building lasting partnerships based on trust and transparency. Integrity guides every action, ensuring we operate with honesty and accountability. Our dedication to excellence means we consistently provide high-quality, reliable services, and our pursuit of continuous improvement keeps us adaptable and responsive in a rapidly evolving tech landscape.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default VMC;