import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import logoImage from '../images/digital.png';

function NavBar() {
    const [isSticky, setIsSticky] = useState(false);

    // Define your styles here
    const styles = {
        navbar: {
            backgroundColor: "#0d3b66", // Dark blue color
            padding: "10px 20px",
            transition: "top 0.3s ease", // Transition effect for sticky
            zIndex: 1000, // Make sure navbar is on top of other elements
        },
        stickyNavbar: {
            position: "sticky",
            top: 0,
            width: "100%",
            backgroundColor: "#003366", // Darker blue color for sticky effect
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional shadow effect for stickiness
        },
        navLink: {
            color: "white",
        },
        socialIcons: {
            display: "flex",
            gap: "1em",
            color: "white",
            fontSize: "1.2em",
        }
    };

    // Scroll event to make navbar sticky
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsSticky(true); // Activate sticky navbar after scrolling down
            } else {
                setIsSticky(false); // Deactivate sticky navbar when scrolled up
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll); // Clean up the event listener
        };
    }, []);

    return (
        <nav
            className={`navbar navbar-expand-lg shadow ${isSticky ? 'sticky-navbar' : ''}`}
            style={isSticky ? { ...styles.navbar, ...styles.stickyNavbar } : styles.navbar}
        >
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <div className="logo-image">
                        <img src={logoImage} width="30" height="30" alt="Logo" />
                    </div>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/" className="nav-link active" style={styles.navLink}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className="nav-link active" style={styles.navLink}>About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact" className="nav-link active" style={styles.navLink}>Contact Us</Link>
                        </li>
                    </ul>
                    {/* Social Media Icons */}
                    <div className="social-icons" style={styles.socialIcons}>
                        <a href="https://www.instagram.com/ciits24?igsh=eDFlYXd0czFvZTc4" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                        <a href="https://www.instagram.com/ciits24?igsh=eDFlYXd0czFvZTc4" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                        <a href="https://x.com/contactciits?t=q3tB7vKEypefWYoDydo2KA&s=08" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                        {/* <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer"><FaPinterest /></a> */}
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
