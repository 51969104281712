import React from "react";
import Slider from '../inc/Slider';
import { Link } from "react-router-dom";
import VMC from "./inc/Vmc"; 
// FontAwesome icons for the service section
import { FaLaptopCode, FaMobileAlt, FaRobot, FaHeadset } from 'react-icons/fa';

function Home() {
    return (
        <div>
            <Slider />
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="main-heading">Our Company</h3>
                            <div className="underline mx-auto"></div>
                            <p style={{ maxWidth: "800px", margin: "20px auto" }}>
                                At CIITS (Central India IT Services), we specialize in providing reliable, innovative IT services that empower businesses to thrive in an increasingly digital world. Our mission is simple: to deliver high-quality, customized IT solutions that streamline operations, enhance productivity, and drive growth for our clients.
                            </p>
                            <Link to="/about" className="btn btn-warning shadow">Read More</Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* Service Section */}
            <section className="service-section py-5">
                <div className="container text-center">
                    <h3 className="main-heading">Our Services</h3>
                    <div className="underline mx-auto mb-4"></div>
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <div className="service-card">
                                <FaLaptopCode size={50} color="#0d3b66" />
                                <h5>Web Development</h5>
                                <p>Custom-built websites designed to elevate your online presence.</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="service-card">
                                <FaMobileAlt size={50} color="#0d3b66" />
                                <h5>Mobile App Development</h5>
                                <p>Feature-rich, user-friendly mobile applications for Android and iOS.</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="service-card">
                                <FaRobot size={50} color="#0d3b66" />
                                <h5>AI Chat Bots</h5>
                                <p>AI-driven chatbots that improve customer engagement and support.</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="service-card">
                                <FaHeadset size={50} color="#0d3b66" />
                                <h5>AI Customer Voice Assistant</h5>
                                <p>Voice-enabled AI assistants to enhance customer service and support.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Vision, Mission, and Values */}
            <VMC />
        </div>
    );
}

export default Home;
